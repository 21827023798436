import React, { Fragment, useEffect, useState } from 'react';
import { Button, Input, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import './PdfView.css';
import axios from 'axios';
import { baseURL, useQuery } from '../../helpers';
import jsPDF from 'jspdf'
import parse from 'html-react-parser';
import SingleMap from '../Map/SingleMap';
import { Document, Page, Text, View, StyleSheet, PDFViewer, renderToFile, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import logo from './vita-logo-1.png'
import comfortaa from '../../assets/font/Comfortaa-Light.ttf'

Font.register({ family: 'Comfortaa', src: comfortaa });

function PdfView() {
    var query = useQuery();
    const [properties, setProperties] = useState(null)
    const [allProperties, setAllProperties] = useState([])
    // const [isHeaderShown, setIsHeaderShown] = useState(true)
    const hideHeader = query.get("hide")
    const [allTypes, setAllTypes] = useState()
    const [allFeatures, setAllFeatures] = useState()
    const slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    useEffect(async () => {
        getAllTypes()
        getAllFeatures()

        const properties = await axios.get(`${baseURL}/wp-json/wl/v23/view/properties/${slug}`)
        setProperties(properties.data)

        const allProperties = await axios.get(baseURL + '/wp-json/wp/v2/property?per_page=100')
        const allProperties2 = await axios.get(baseURL + '/wp-json/wp/v2/property?per_page=100&page=2')
        setAllProperties([...allProperties.data, ...allProperties2.data])
    }, [])

    async function getAllTypes() {
        const types = await axios.get(`${baseURL}/wp-json/wp/v2/property_type`)
        setAllTypes(types.data)
    }

    async function getAllFeatures() {
        const features = await axios.get(`${baseURL}/wp-json/wp/v2/property_feature?per_page=100`)
        const features1 = await axios.get(`${baseURL}/wp-json/wp/v2/property_feature?per_page=100&page=2`)
        let featureArr = [...features.data, ...features1.data]
        setAllFeatures(featureArr)
    }
    return (
        <div>
            {/* {properties && <PDFDownloadLink document={<MyDoc properties={properties} />} fileName={`${properties.title} ${new Date().toLocaleDateString()}`}>
                {({ blob, url, loading, error }) =>
                    loading ? <button className='PDF-Button' disabled><i className="far fa-file-pdf"></i> Download as PDF</button> : <button className='PDF-Button'><i class="far fa-file-pdf"></i> Download as PDF</button>
                }
            </PDFDownloadLink>} */}
            {properties && allProperties.length > 0 ? <PDFViewer style={{ width: '100vw', height: '98vh' }}>
                <MyDoc properties={properties} allProperties={allProperties} allTypes={allTypes} allFeatures={allFeatures} hideHeader={hideHeader} />
            </PDFViewer>
                : <div className='loading-screen'>
                    <div className='loading-wrapper'>
                        <img className='loading-image' src={logo} />
                        <span className='loading-text'>Loading . . .</span>
                    </div>
                </div>
            }
        </div>
    );
}

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Comfortaa'
    },
    headerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 15,
    },
    headerText: {
        flexDirection: 'column',
        flex: 0.65,
        paddingRight: '20px',
        justifyContent: 'flex-end',
    },
    headerTextAlt: {
        flexDirection: 'column',
        flex: 1,
        paddingRight: '20px',
        justifyContent: 'flex-end',
    },
    headerTextTitle: {
        color: "#545454",
        fontSize: 26,
        marginBottom: 8,
    },
    logoWrapper: {
        flex: 0.35,
    },
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#595959',
        justifyContent: 'space-between',
        paddingHorizontal: 30,
        paddingVertical: 10,
        color: 'white',
        fontSize: 16,
        width: '100vw',
    },
    text: {
        fontSize: 13,
        textAlign: 'justify',
        lineHeight: 1.6,
        marginBottom: 7,
        color: '#5c5c5c',
    },
    description1: {
        fontSize: 14,
        textAlign: 'justify',
        lineHeight: 1.6,
        marginBottom: 7,
        color: '#474747',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: -35,
    },
    propertyDescription: {
        marginVertical: 15,
        marginRight: 30,
        display: 'flex',
        flexDirection: 'row',
        fontSize: 13,
        justifyContent: 'space-between'
    },
    listHeader: {
        marginBottom: 3,
        fontSize: 13,
        textAlign: 'justify',
        lineHeight: 1.6,
        color: '#474747',
    },
    bulletList: {
        paddingLeft: 5,
        fontSize: 13,
        textAlign: 'justify',
        lineHeight: 1.6,
        color: '#474747',
    },
    section: {
        marginVertical: 15,
        display: 'flex'
    },
    sectionTitle: {
        marginBottom: 15,
        color: '#474747',
    },
    gallerySection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    gallery: {
        width: '46%',
        margin: 10,
        maxHeight: 180,
        objectFit: 'cover',
    }
});

// Create Document Component
const MyDoc = ({ allProperties, properties, allTypes, allFeatures, hideHeader }) => {
    const [descriptionArray, setDescriptionArray] = useState([])
    const [types, setTypes] = useState()
    const [features, setFeatures] = useState()
    const [featureImage, setFeatureImage] = useState(null)
    var hide = hideHeader === 'true'

    useEffect(() => {
        if (properties.content) {
            var properyDescriptionArray = properties.content.replace(/\r?<p>|<\/p>|<!-- wp:paragraph -->|\n\n|<!-- \/wp:paragraph -->|\r/g, '').split("\n").filter(e => e !== '')
            properyDescriptionArray.pop()
            setDescriptionArray(properyDescriptionArray)
        }
        if (allProperties && properties) {
            const targetProperty = allProperties.find((data) => data.id == properties.id)
            if (targetProperty) {
                if (allTypes) {
                    const targetTypes = allTypes.filter((type) => targetProperty.property_type && targetProperty.property_type.length ? targetProperty.property_type.includes(type.id) : undefined)
                    if (targetTypes) {
                        setTypes(targetTypes.map((type) => type.name))
                    }
                }
                if (allFeatures) {
                    const targetFeatures = allFeatures.filter((feature) => targetProperty.property_feature && targetProperty.property_feature.length ? targetProperty.property_feature.includes(feature.id) : undefined)
                    if (targetFeatures) {
                        setFeatures(targetFeatures.map((feature) => feature.name))
                    }
                }
            }
        }
    }, [properties, allProperties, allTypes, allFeatures])

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.headerWrapper} fixed>
                    <View style={hide ? styles.headerTextAlt : styles.headerText}>
                        <Text style={styles.headerTextTitle}>{properties.title}</Text>
                        <Text style={{ color: '#5c5c5c' }}>{properties.type}: €{properties.price}</Text>
                    </View>
                    {!hide && <View style={styles.logoWrapper}>
                        <Image src={logo} />
                    </View>}
                </View>
                {properties.gallery && <Image
                    style={styles.image}
                    src={properties.gallery[0].sizes.large}
                />}
                {descriptionArray.map(e => <Text style={styles.text} key={e}>{parse(e)}</Text>)}
                <View style={styles.propertyDescription}>
                    <View style={styles.description1}>
                        <Text style={{ fontSize: 13, }}>Property Type: {types && types.length ? types.map((type, i) => <Text style={{ fontSize: 13, }} key={i}>{type}</Text>) : ""}</Text>
                        <Text style={{ fontSize: 13, }}>Bedrooms: {properties.bedrooms}</Text>
                        <Text style={{ fontSize: 13, }}>Bathrooms: {properties.bathrooms}</Text>
                    </View>
                    <View>
                        <Text style={styles.listHeader}>Features</Text>
                        {features && features.length ? features.map((feature, i) => (
                            <Text key={i} style={styles.bulletList}>{'\u2022'} {feature}</Text>
                        )) : null}
                    </View>
                </View>
                <View style={styles.section}>
                    {/* <Text style={styles.sectionTitle}>Location</Text> */}
                    {properties && properties.location && (properties.location.lat !== "" && !properties.location.lng !== "") ? (
                        <Image src={`https://maps.googleapis.com/maps/api/staticmap?center=${properties.location.lat},${properties.location.lng}&zoom=13&size=600x300&markers=${properties.location.lat},${properties.location.lng}&key=AIzaSyCSsjQFF6TzrCgxAc_ckscksZL2LkpTnIQ`} />
                    ) : null}
                </View>
                <View style={styles.gallerySection}>
                    {properties.gallery && properties.gallery.map((gallery, i) => {
                        if (i === 0) return null
                        if (i > 16) return null
                        return (
                            <Image
                                src={gallery.sizes.medium}
                                key={i}
                                style={styles.gallery}
                            />
                        )
                    })}
                </View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Floor Plan</Text>
                </View>
                {!hide && <View style={styles.footerContainer} fixed>
                    <Text>vitavip.es</Text>
                    <Text>info@vitavip.es</Text>
                    <Text>+44 7533 847477</Text>
                </View>}
            </Page>
        </Document>
    );
};

export default PdfView;
import React, { Fragment, useEffect, useState } from 'react';

function PdfView() {
    return (
        <div>
            Landing Page
        </div>
    );
}

export default PdfView;
import React, { Fragment, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './Map.css';
import pin from '../../assets/map-pin.png';
import { MapSkin } from './MapSkin';

function SingleMap({ location }) {
    const defaultCenter = location ? {
        lat: Number(location.lat),
        lng: Number(location.lng)
    } : {
        lat: 36.5008304,
        lng: -4.93504
    }

    return (
        <div style={{ height: '60vh', width: '300px' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                defaultCenter={defaultCenter}
                defaultZoom={13}
                options={{
                    styles: MapSkin, disableDefaultUI: true,
                    fullscreenControl: false
                }}
            >
                <div
                    lat={location.lat}
                    lng={location.lng}
                    style={{ position: 'relative' }} >
                    <div className="map-icon">
                        <img className="map-pin" src={pin} />
                    </div>
                </div>
            </GoogleMapReact>
        </div>
    );
}

export default SingleMap;
import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Map from './pages/Map/Map';
import PdfView from './pages/PdfView/PdfView';
import CoordinateMigration from './pages/CoordinateMigration/CoordinateMigration'
import LandingPage from './pages/LandingPage/LandingPage'

function App() {
  console.log('Developed by %c ', 'font-size: 1px; padding: 8px 20px; line-height: 0; background: url("https://mindset.swiss/wp-content/uploads/2020/12/mindset_logo.png"); background-size: contain; background-repeat: no-repeat; color: transparent;');
  console.log('https://mindset.swiss/')
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={LandingPage}/>
        <Route path='/migration' exact component={CoordinateMigration} />
        <Route path='/wp/map' exact component={Map} />
        <Route path='/wp/pdf-view/:id' exact component={PdfView} />
      </Switch>
    </Router>
  );
}

export default App;

import React, { Fragment, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, Input, Form, FormGroup, Label, Col, Row, Table } from 'reactstrap';
import './Map.css';
import pin from '../../assets/map-pin.png';
import pinAlt from '../../assets/map-pin-alt.png';
import close from '../../assets/close.png';
import axios from 'axios';
import { baseURL, useQuery } from '../../helpers';
import { MapSkin } from './MapSkin';

function Map() {
    var query = useQuery();
    const [isCardShown, setIsCardShown] = useState(false)
    const [properties, setProperties] = useState([])
    const [activeCard, setActiveCard] = useState()
    const propertyId = query.get("id")
    const slugUrl = query.get("slug")
    const type = query.get("type")  // For Sale || Starting Nightly Price
    const [defaultCenter, setDefaultCenter] = useState(null)
    const [zoom, setZoom] = useState(12)

    useEffect(() => {
        axios.get(baseURL + '/wp-json/wl/v41/properties').then(res => {
            setProperties(res.data)
            if (slugUrl) {
                const property = res.data.find((property) => property.slug === slugUrl)
                if (property) {
                    setDefaultCenter({
                        lat: Number(property.location.lat),
                        lng: Number(property.location.lng)
                    })
                }
            } else {
                setDefaultCenter({
                    lat: 36.5008304,
                    lng: -4.93504
                })
            }
        }).catch(err => {
            throw err;
        })
    }, [])

    function toggleCard(cardId, isShown, location = undefined) {
        if (activeCard) {
            document.getElementById(`card-details-${activeCard}`).style.display = "none";
        }
        document.getElementById(`card-details-${cardId}`).style.display = isShown ? "block" : "none";
        setActiveCard(cardId)
        if (location) {
            console.log(1 / Math.pow(2, zoom / 2))

            setDefaultCenter({
                lat: Number(location.lat),
                lng: Number(location.lng)
            })
        }
    }
    /*
        Rent
        luxury-6-bedroom-villa
        luxury-contemporary-villa
        villa-la-corsa

        Sale
        beautiful-renovated-apartment-located-in-estepona
        classic-villa-in-puerta-los-reales-estepona
    */
    // console.log(properties.filter(e => isNaN(e.lat) && isNaN(e.lng) && e.type.trim() == type))

    if (!defaultCenter) return <div></div>

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                defaultCenter={defaultCenter}
                center={defaultCenter}
                defaultZoom={slugUrl ? 15 : 12}
                onChange={({ zoom }) => setZoom(zoom)}
                options={{ styles: MapSkin, clickableIcons: false }}
            >
                {properties.length ? properties.filter(e => isNaN(e.lat) && isNaN(e.lng)).map((data, i) => {
                    if (slugUrl) {
                        if (data.slug != slugUrl) { return false }
                        return (
                            <div
                                lat={Number(data.location.lat)}
                                lng={Number(data.location.lng)}
                                style={{ position: 'relative' }} >
                                <div id={`card-details-${i}`} className="card-wrapper">
                                    <div className="card-details">
                                        <div className="card-close" onClick={() => toggleCard(i, false)}>
                                            <img className="icon" src={close} />
                                        </div>
                                        <div className="map-card-header">
                                            <p className="card-title">{data.title}</p>
                                            <p className='card-address'>{data.address}</p>
                                        </div>
                                        <div className="media">
                                            <img className="cover" src={data.thumbnail_url} />
                                            <div className="media-details">
                                                <p>{data.excerpt.length > 150 ? data.excerpt.substr(0, 150) + "..." : data.excerpt}</p>
                                                <p>{data.type.trim()}: {data.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="card-arrow"></span>
                                </div>
                                <div className="map-icon" onClick={() => toggleCard(i, true)}>
                                    <img className="map-pin" src={data.type.trim() === 'Starting Nightly Price' ? pin : pinAlt} />
                                </div>
                            </div>
                        )
                    } else {
                        if (data.type.trim() != type) { return false }
                        return (
                            <div
                                lat={Number(data.location.lat)}
                                lng={Number(data.location.lng)}
                                style={{ position: 'relative' }} >
                                <div id={`card-details-${i}`} className="card-wrapper">
                                    <div className="card-details">
                                        <div className="card-close" onClick={() => toggleCard(i, false)}>
                                            <img className="icon" src={close} />
                                        </div>
                                        <div className="map-card-header">
                                            <p className="card-title">{data.title}</p>
                                            <p className='card-address'>{data.address}</p>
                                        </div>
                                        <div className="media">
                                            <img className="cover" src={data.thumbnail_url} />
                                            <div className="media-details">
                                                <p className="media-text">{data.excerpt.length > 150 ? data.excerpt.substr(0, 150) + "..." : data.excerpt}</p>
                                                <p className="media-text">{data.type.trim()}: &#8364;{data.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                <Button className="custom-btn" onClick={() => window.open(`https://vitavip.es/property/${data.slug}`, "_parent")}>VIEW PROPERTY</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="card-arrow"></span>
                                </div>
                                <div className="map-icon" onClick={() => toggleCard(i, true, data.location)}>
                                    <img className="map-pin" src={data.type.trim() === 'Starting Nightly Price' ? pin : pinAlt} />
                                </div>
                            </div>
                        )
                    }
                }) : null}
            </GoogleMapReact>
        </div>
    );
}

export default Map;
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Input, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import axios from 'axios';
import { baseURL } from '../../helpers';
import coordinates from './Coordinates.json'

function PdfView() {
    const [isCardShown, setIsCardShown] = useState(false)
    const [properties, setProperties] = useState([])
    const [activeCard, setActiveCard] = useState()

    useEffect(() => {

    }, [])

    const migrateData = () => {
        coordinates
            // .filter(e => e.Type === 'Sale')
            .forEach(({ Slug, Lat, Lng }) => {
                console.log(`http://vitavip.es/wp-json/wl/v21/update/properties/${Slug}?loc_lat=${Lat}&loc_lng=${Lng}`)
            })
    }

    return (
        <div>
            <button onClick={migrateData}>Migrate</button>
        </div>
    );
}

export default PdfView;